import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "../css/commands.css";

// Main react component function
function CommandsComponent() {
  // Create the states
  const [commands, setCommands] = useState([]);

  useEffect(() => {
    const getData = async() =>{
      await axios.get("https://www.hexagonbot.com/botStats")
      .then(response => {
        const data = response.data;
        const botCommands = data.commands[0];
        setCommands(botCommands);
      })
      .catch(error => {
        throw new Error(`An error occured while fetching the bot data: ${error}`);
      });
    }
    getData();
  }, []);

  const commandsArray = Object.values(commands);

  const moderationCommands = commandsArray.filter((command) => {
    return command.commandCategory === "Moderation";
  });

  const ticketCommands = commandsArray.filter((command) => {
    return command.commandCategory === "Tickets";
  });

  const musicCommands = commandsArray.filter((command) => {
    return command.commandCategory === "Music";
  });

  return (
    <div className="commands-container">
        <div>
          <p className="title">Moderation Commands</p>
          <div className="commands">
            {moderationCommands.map((command) => (
              <div className="command" key={command.commndName}>
                <div className="info">
                  <p className="title">
                    {command.isPremium ? (
                      <>
                        <FontAwesomeIcon icon={faCrown} className="premium-crown" />
                        {command.displayName}
                      </>
                      ) : (
                        command.displayName
                    )}
                  </p>
                  <p className="description">{command.commandDescription.replace(".", "")}</p>
                  <p className="usage">/{command.commandName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <p className="title"><FontAwesomeIcon icon={faCrown} className="premium-crown"/> Music Commands</p>
          <div className="commands">
            {musicCommands.map((command) => (
              <div className="command" key={command.commndName}>
                <div className="info">
                  <p className="title">
                    {command.isPremium === true ? (
                      <>
                        <FontAwesomeIcon icon={faCrown} className="premium-crown" />
                        {command.displayName}
                      </>
                      ) : (
                        command.displayName
                    )}
                  </p>
                  <p className="description">{command.commandDescription.replace(".", "")}</p>
                  <p className="usage">/{command.commandName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <p className="title">Ticket Commands</p>
          <div className="commands">
            {ticketCommands.map((command) => (
              <div className="command" key={command.commndName}>
                <div className="info">
                  <p className="title">
                    {command.isPremium ? (
                      <>
                        <FontAwesomeIcon icon={faCrown} className="premium-crown" />
                        {command.displayName}
                      </>
                      ) : (
                        command.displayName
                    )}
                  </p>
                  <p className="description">{command.commandDescription.replace(".", "")}</p>
                  <p className="usage">/{command.commandName}</p>
                </div>
              </div>
               ))}
          </div>
        </div>
    </div>
  );
}

export default CommandsComponent;