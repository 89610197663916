import { Helmet } from 'react-helmet';
import '../css/terms.css';

// Main react page function
function Terms() {
  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Terms of Use - Hexagon</title>
      </Helmet>

      <div className="container-terms">
        <p className="title">Terms of Service</p>
        <p className="description">Last Updated: April 3rd, 2024</p>
        <div className="welcome-section">
          <p className="title">Welcome!</p>
          <p>
            We appreciate your usage of Hexagon! The following Terms are crafted for adherence by all users. By utilizing Hexagon Bot or any of Hexagon's services, you consent to these terms.
          </p>
        </div>

        <div className="content-section">
          <p className="title">Content on Hexagon</p>
          <p>Unless otherwise specified or clearly stated, all content available on Hexagon is either owned or provided by the Owner or its licensors.
          <br/><br/>The Owner endeavors to ensure that the content on Hexagon complies with all applicable legal provisions and respects third-party rights. However, achieving absolute compliance may not always be feasible. In such instances, Users are kindly urged to report any related complaints using the contact details provided in this document. This request is made without prejudice to any legal rights Users may have to enforce their own rights.</p>
        </div>

        <div className="acceptable-use-section">
          <p className="title">Acceptable Use</p>
          <p>
            Hexagon and the service may only be used within the scope of what they are provided for, under these Terms and applicable law.
            Users are solely responsible for making sure that their use of Hexagon and/or the service violates no applicable law, regulations or third-party rights.
            <br/><br/>
            Therefore, the Owner reserves the right to take any appropiate measure to protect its legitmate interests including denying Users access to Hexagon or the service, terminating contracts, reporting any misconduct performed through Hexagon or the service to the competant authorities - such as judicial or administrative authorties - whenever the users are suspected to be in violation of any laws, regulations, third-party rights and/or these terms, including, but not limited to, by engaging in any of the following activities:
          </p>
          <ul>
            <li>Concealing their identity, stealing someone else's identity, or pretending to be or represent a third party if not allowed to do so by the mentioned third party.</li>
            <li>Manipulating identifiers to disguise or otherwise conceal the origin of their messages or the content posted.</li>
            <li>Defaming, abusing, harassing, using threatening practices, or violating the legal rights of others in any way.</li>
            <li>Promoting activity that may endanger the user's life or the life of any other user or lead to physical harm.</li>
            <li>Probing, scanning, or testing the vulnerability of Hexagon, including the services or any network connected to Hexagon, or breaching the security or authentication measures on Hexagon.</li>
            <li>Installing, embedding, uploading, or otherwise incorporating any malware into or via Hexagon.</li>
            <li>Using Hexagon or the technical infrastructure in an abusive, excessive, or otherwise inappropriate way.</li>
            <li>Pretending to purchase any products offered via Hexagon without any real intent to do so.</li>
            <li>Failing to pay for products purchased.</li>
          </ul>
        </div>

        <div className="excessive-use-section">
          <p className="title">Excessive Use of the API</p>
          <p>Sending abusive or excessively frequent requests to the service via the API may result in actions taken by the owner. The owner reserves the right to determine what constitutes excessive or abusive behavior and additionally reserves the right to temporarily or permanently suspend access to the API by the user. In such cases, the owner will make a reasonable attempt to notify the user prior to the suspension.</p>
        </div>

        <div className="scraping-section">
          <p className="title">Scraping</p>
          <p>Adopting any automated process to extract, harvest, or scrape information, data and/or content from Hexagon and all the digital properties associated, unless where explicitly allowed to do so by the Owner.</p>
        </div>

        <div className="user-section">
          <p className="title">User Protection</p>
          <p>Misappropiating any account in use by another User, harvesting or collecting any personally identifying information of other users, using any information relating to other users, including personal and contact data, for purposes other than Hexagon is intended for.</p>
        </div>
      </div>
    </>
  );
}

export default Terms;