import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import "../css/logs.css";

// Main react component function
function LogsComponent() {
  return (
    <div className="logs-container">
      <div>
        <p className="title">Dashboard Logs</p>
        <div className="logs">
          {/* <div className="log">
          </div> */}
          <p className="no-logs"><FontAwesomeIcon icon={faWarning} /> No logs to display!</p>
        </div>
      </div>
    </div>
  );
}

export default LogsComponent;