import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import FeaturesSectionHome from "../components/FeaturesSectionHome.js";
import FeaturedServersHome from "../components/FeaturedServersHome.js";
import "../css/home.css";

// Main react page function
function Home() {
  // Create the state
  const [botStats, setBotStats] = useState({});

  useEffect(() => {
    // Function to get the bot stats
    async function getStats() {
      await axios.get("https://www.hexagonbot.com/botStats")
        .then(response => {
          const data = response.data;
          setBotStats(data);
        })
        .catch(error => {
          throw new Error(`An error occured while fetching the bot stats: ${error}`);
        });
    }
    getStats();
  }, []);

  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Hexagon - The Ultimate Discord Bot</title>
      </Helmet>

      <ins className="adsbygoogle"
        id="home-ad-one"
        data-ad-client="ca-pub-1487845648283534"
        data-ad-slot="3294794823"
        data-ad-format="auto"
        data-full-width-responsive="true">
      </ins>

      <div className="landing-section-home">
          <p className="title">The Ultimate Discord Bot for Seamless Community Management.</p>
          <p className="description">The perfect Discord Bot for your Discord Community, equipped with a wide array of features. Simplify your Community Management like never before!</p>
          <p className="description-2">As of 2024, Hexagon is trusted by over <span className="blue-highlight">{botStats.totalMembers}</span> users. We invite you to use it and hope you enjoy!</p>
        
          <div className="buttons">
              <a href="https://discord.com/api/oauth2/authorize?client_id=1078264877744930827&permissions=8&scope=bot" className="add-to-server-button">Add to Server</a>
              <a href="/dashboard" className="dashboard-button">Dashboard</a>
          </div>
      </div>

      <FeaturesSectionHome />
      <FeaturedServersHome />

      <div className="getting-started-section">
          <p className="title">Get Started</p>
          <p className="description">Click the button below to log in and commence your journey.</p>

          <a href="/dashboard" className="dashboard-button">Dashboard</a>
      </div>

      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </>
  );
}

export default Home;