import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faWarning } from "@fortawesome/free-solid-svg-icons";
import LZString from "lz-string";
import Cookies from "js-cookie";
import "../css/dashboardComponent.css";

// Function to decompress the data
const decompressData = (compressedString) => {
  let returning;
  try {
    const decompressed = LZString.decompressFromBase64(compressedString);
    returning = JSON.parse(decompressed);
  } catch (error) {
    return;
  }
  return returning;
};

// Main react component function
function DashboardComponent() {
  // React states
  const [userGuilds, setUserGuilds] = useState([]);

  useEffect(() => {
    // Get the cookie
    const userGuildsCookie = Cookies.get("discordOwnedGuilds");

    // If the cookie exists then set the data
    if (userGuildsCookie) {
      const guilds = decompressData(userGuildsCookie);

      setUserGuilds(guilds);
    }
  }, []);

  return (
    <>
      <p className="guilds-section-title">Select a Server</p>
      <p className="guilds-section-description">
        View, manage and update your servers here.
      </p>

      <div className="guild-section">
        {/* If the user owns a guild display it, else display a placeholder */}
        {userGuilds.length === 0 ? (
          <p className="no-servers"><FontAwesomeIcon icon={faWarning} /> You have no servers to display</p>
        ) : (
          userGuilds.map((guild) => (
            <div className="guild" key={guild.id}>
              <div className="content">
                <div className="guild-background">
                  {guild.icon && <img src={guild.icon} alt="Icon" />}
                </div>

                <div className="guild-icon">
                  {guild.icon ? (
                    <img src={guild.icon} alt="Icon" />
                  ) : (
                    <p>{guild.name.substring(0, 2).toUpperCase()}</p>
                  )}
                </div>
              </div>

              <p className="guild-name">{guild.name}</p>
              <Link
                to={`/dashboard/${guild.id}`}
                className="manage-button"
              >
                <FontAwesomeIcon icon={faCog} />
              </Link>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default DashboardComponent;