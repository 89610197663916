import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import axios from "axios";
import logo from "../images/logo.png";
import "../css/premium.css";

const stripe_pkey = "pk_live_51OQJekDBmbRsbz3tytQFaJhfYdQR2TIYgoGEcaZL8pgjzhlc6TWvvHCpZFartkJVdDFOW9WfXeenDag9TcdHfQVD00sN7pclWL";

// Main react page function
function Premium() {
  // Function to handle checkout
  const handleCheckoutClick = async () => {
    // Get the cookie
    const userInfoCookie = Cookies.get("userInfo");

    // If the cookie exists begin the checkout process, if it doesnt then ask the user to login
    if (userInfoCookie) {
      const userInfo = JSON.parse(userInfoCookie);

      try {
        const response = await axios.post("https://www.hexagonbot.com/createCheckoutPremium", {
          account_data: userInfo,
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        const session = response.data;
        const stripe = await loadStripe(stripe_pkey);
        await stripe.redirectToCheckout({ sessionId: session.id });
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      window.location.replace("https://discord.com/oauth2/authorize?client_id=1078264877744930827&response_type=token&redirect_uri=https%3A%2F%2Fwww.hexagonbot.com%2F&scope=identify+guilds+guilds.members.read");
    }
  };

  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Premium</title>
      </Helmet>

      <div className="landing-section-premium">
        <img src={logo} alt="logo" width="100px" height="100px"></img>
        <p className="title">Hexagon Premium</p>
        <p className="description">Purchasing Premium not only gives you a wide range of awesome abilities, but it also inspires us to create more content for our users!</p>
      </div>

      <div className="premium-section">
        <div className="plan-box">
          <p className="title">Free Plan</p>

          <ul className="features-list">
            <li>Access 26 complimentary commands</li>
            <li>Maximum of 25 tickets in your server</li>
          </ul>
          
          <a href="https://discord.com/api/oauth2/authorize?client_id=1078264877744930827&permissions=8&scope=bot" className="purchase">Add to Server</a>
        </div>
        <div className="plan-box">
          <p className="title">Premium Plan</p>

          <ul className="features-list">
            <li>Access 26 complimentary commands</li>
            <li>Unlock exclusive premium commands</li>
            <li>Unlimited tickets in your server</li>
            <li>Acquire a Hexagon Badge</li>
            <li>Special role in the <a href="https://discord.gg/UWA8ujD7Th"><span className="blue-highlight">Hexagon Community</span></a></li>
            <li>Music Commands</li>
            <li>Featured in <a href="https://www.hexagonbot.com/servers"><span className="blue-highlight">Public Servers</span></a></li>
          </ul>

          <p className="purchase" onClick={handleCheckoutClick}>Subscribe for £2.49 / month</p>
        </div>
      </div>
    </>
  );
}

export default Premium;