import { useEffect, useState } from "react";
import axios from "axios";
import "../css/featuresWebhooks.css";

// Main react component function
function FeaturesSectionWebhooks() {
  // Create the state
  const [webhookData, setWebhookData] = useState({});

  useEffect(() => {
    // Function to get the webhook data
    async function updateData() {
      await axios.get("https://www.hexagonbot.com/webhookStats")
        .then(response => {
          const data = response.data;
          // Set the state
          setWebhookData(data);
        })
        .catch(error => {
            throw new Error(`An error occured while fetching the webhook data: ${error}`);
        });
    };
    updateData();
  }, [webhookData]);

  return (
    <div className="features-section-webhooks">
      <p className="title">Features</p>
      <p className="description">Hexagon boasts a multitude of impressive features, and here are some of the standout highlights.</p>
      <div className="feature-boxes">
        <div className="feature-box">
          <p className="title">Requests</p>
          <p className="description">Since Jan 2023 we have served <span className="blue-highlight">{webhookData.totalWebhooks}</span> webhooks and processed <span className="blue-highlight">{webhookData.totalRequests}</span> requests.</p>
        </div>

        <div className="feature-box">
          <p className="title">Updated</p>
          <p className="description">Our staff team aims to deploy weekly updates, to keep things working.</p>
        </div>

        <div className="feature-box">
          <p className="title">Setup</p>
          <p className="description">Setup is easy. All you need to do is replace <span className="blue-highlight">discord.com</span> with <span className="blue-highlight">webhooks.hexagonbot.com</span>!</p>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSectionWebhooks;