import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import "../css/featuredHome.css";

function FeaturedServersHome() {
  const [servers, setServers] = useState([]);

  useEffect(() => {
    axios.get("https://www.hexagonbot.com/getServers")
      .then((res) => {
        const servers = res.data;
        setServers(servers);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        throw new Error("An error occurred while trying to get the servers");
      });
  }, []);
  
  const sortedServers = servers.slice(0, 12);

  return (
    <>
    <div className="servers-container-home">
      <p className="title">Public Servers</p>
      <p className="description">A quality, well-made listing service. View the full list here <a className="home-page-link" href="https://www.hexagonbot.com/servers">Public Servers</a>.</p>

      <div className="server-boxes">
      {sortedServers.map((server, index) => (
        <div className="server" key={server.GuildId}>
          <div className="server-icon">
            {server.guildIconUrl ? (
              <img src={server.guildIconUrl} alt="Icon" />
            ) : (
              <p>{server.name.substring(0, 2).toUpperCase()}</p>
            )}
          </div>
          <p className="server-name">{server.name ? server.name : "Placeholder"}</p>
          <p className="server-description">{server.description ? server.description : "A very interesting server!"}</p>
          <div className="server-buttons">
            <p className="member-count">{server.memberCount} Members</p>
            <a className="join" href={server.invite}>Join <FontAwesomeIcon icon={faExternalLinkAlt} className="icon" /></a>
          </div>
          {(index + 1) % 3 === 0 && <div className="clearfix" />}
        </div>
      ))}
      </div>
    </div>
    </>
  );
}

export default FeaturedServersHome;