import { useState } from "react";
import { Helmet } from "react-helmet";
import FeaturesSectionWebhooks from "../components/FeaturesSectionWebhooks.js";
import logo from "../images/logo.png";
import "../css/webhooks.css";

function Webhooks() {
  // Create the states
  const [inputValue, setInputValue] = useState("");
  const [outputValue, setOutputValue] = useState("");

  // Function to handle webhook conversions
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    const editedValue = event.target.value.replace("https://discord.com/", "https://webhooks.hexagonbot.com/");
    setOutputValue(editedValue);
  };

  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Hexagon Webhooks</title>
        <meta name="description" content="Hexagon Webhooks: Your all-in-one Discord Webhook Proxy for Roblox Development." />
        <meta property="og:title" content="Hexagon Webhooks" />
        <meta property="og:description" content="Hexagon Webhooks: Your all-in-one Discord Webhook Proxy for Roblox Development." />
      </Helmet>

      <div className="landing-section-webhooks">
        <img src={logo} alt="logo" width="100px" height="100px"></img>
        <p className="title">Hexagon Webhooks</p>
        <p className="description">Hexagon Webhooks serves as a Discord Webhook Proxy designed specifically for Roblox games.</p>
        <div className="input-section">
          <input
            type="text"
            className="inputBox"
            spellCheck="false"
            placeholder="Webhook URL"
            value={inputValue}
            onChange={handleInputChange}
          />
          <p className="arrow">➜</p>
          <input
            type="text"
            className="outputBox"
            spellCheck="false"
            placeholder="Output URL"
            readOnly
            value={outputValue}
          />
        </div>
      </div>

      <FeaturesSectionWebhooks />
    </>
  );
}

export default Webhooks;