import { useEffect, useState } from "react";
import axios from "axios";
import "../css/featuresHome.css";

// Main react component function
function FeaturesSectionHome() {
  // Create the state
  const [botData, setBotData] = useState({});

  useEffect(() => {
    // Function to get the bot data
    async function updateData() {
      await axios.get("https://www.hexagonbot.com/botStats")
        .then(response => {
          const data = response.data;

          // Set the state
          setBotData(data);
        })
        .catch(error => {
          throw new Error(`An error occured while fetching the bot data: ${error}`);
        });
    };
    updateData();
  }, []);

  return (
    <div className="features-section-home">
      <p className="title">Features</p>
      <p className="description">Hexagon is distinguished by a plethora of impressive features, and here are several standout highlights that underscore its remarkable capabilities and functionalities.</p>
      <div className="feature-boxes">
        <div className="feature-box">
          <p className="title">Trusted</p>
          <p className="description">Hexagon is actively used by <span className="blue-highlight">{botData.totalMembers}</span> users across <span className="blue-highlight">{botData.totalGuilds}</span> servers.</p>
        </div>

        <div className="feature-box">
          <p className="title">Updated</p>
          <p className="description">Our staff team aims to deploy weekly updates, to keep things working.</p>
        </div>

        <div className="feature-box">
          <p className="title">Tickets</p>
          <p className="description">Our bot has handled over <span className="blue-highlight">{botData.totalHandledTickets}</span> tickets in its lifetime.</p>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSectionHome;