import { Helmet } from 'react-helmet';
import StatusComponent from '../components/StatusComponent.js';
import '../css/status.css';

// Main react page function
function Status() {
  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Status</title>
      </Helmet>

      <StatusComponent />
    </>
  );
}

export default Status;