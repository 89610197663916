import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement } from "chart.js";
import { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "../css/line.css";

// Setup ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
);

function formatData(inputData) {
  const allTimes = Object.keys(inputData.websitePings).concat(Object.keys(inputData.apiPings));
  const labels = Array.from(new Set(allTimes));

  const websitePingsData = [];
  const apiPingsData = [];

  // Process website pings
  labels.forEach((time, index) => {
    const websitePing = inputData.websitePings[time];
    websitePingsData[index] = websitePing ? parseInt(websitePing) : 0;
  });

  // Process API pings
  labels.forEach((time, index) => {
    const apiPing = inputData.apiPings[time];
    apiPingsData[index] = apiPing ? parseInt(apiPing) : 0;
  });

  // Slice the labels and datasets to show only the most recent 10
  const startIndex = Math.max(labels.length - 10, 0);
  const recentLabels = labels.slice(startIndex);
  const recentWebsitePingsData = websitePingsData.slice(startIndex);
  const recentApiPingsData = apiPingsData.slice(startIndex);

  return {
    labels: recentLabels.map(label => label.replace(/ (AM|PM)/i, "")),
    datasets: [
      {
        label: 'Website',
        data: recentWebsitePingsData,
        borderColor: "#27ae60",
        fill: false,
      },
      {
        label: 'Webhooks',
        data: recentApiPingsData,
        borderColor: "#2980b9",
        fill: false,
      }
    ],
  };
}

// Main react component function
function LineComponent() {
  // Create the states
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  // Get the status data
  useEffect(() => {
    axios.get("https://www.hexagonbot.com/getStatus").then((response) => {
      const responseData = formatData(response.data);
      setData(responseData);
    });
  }, []);

  // Define the chart options
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          callback: (value) => value === 0 ? value : value.toFixed(0),
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  return (
    <div className="line-div">
      <Line data={data} options={options} />
    </div>
  );
}

export default LineComponent;