import { Helmet } from "react-helmet";
import logo from "../images/logo.png";
import "../css/careers.css";

// Main react page function
function Careers() {
  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Careers - Hexagon</title>
      </Helmet>

      <div className="landing-section-careers">
        <img src={logo} alt="logo" width="100px" height="100px"></img>
        <p className="title">Hexagon Careers</p>
        <p className="description">Have you ever wanted to be apart of our ever-growing staff team? Do you want to help develop and build discord applications?</p>
        <p className="description-two">You have come to the right place. All open positions are listed below.</p>
      </div>

      <div className="careers-section">
        <p>There are currently no vacant positions.</p>
      </div>
    </>
  );
}

export default Careers;