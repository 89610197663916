import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import DashboardComponent from "../components/DashboardComponent.js";
import "../css/dashboard.css";

// Main react page function
function Dashboard() {
  // Check if the user is logged in, if they aren"t send them to the home page
  const accessToken = Cookies.get("discordAccessToken");
  if (!accessToken) {
    window.location.replace("/");
  }

  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <DashboardComponent />

      <div className="manage-subscription">
          <p className="title">Subscriptions</p>
          <p className="description">Need to cancel or modify your subscription? We have collaborated with Stripe to simplify the process. Head to the Stripe Dashboard and input your payment email for quick updates.</p>
          <a href="https://billing.stripe.com/p/login/28o29vbxp8Bz4hi288">Stripe Dashboard</a>
      </div>
    </>
  );
}

export default Dashboard;