import { Helmet } from "react-helmet";
import "../css/cookies.css";

// Main react page function
function Cookies() {
  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Cookies Policy - Hexagon</title>
      </Helmet>

      <div className="container-cookies">
        <p className="title">Cookies Policy</p>
        <p className="description">Last Updated: April 3rd, 2024</p>

        <div className="welcome-section">
          <p className="title">Welcome!</p>
          <p>
            We appreciate your usage of Hexagon! The guidelines listed below are established to ensure a delightful browsing experience for all visitors. By accessing this page or using any of our cookie-related services, you signify your agreement to these guidelines.
          </p>
        </div>

        <div className="information-section">
          <p className="title">What are Cookies?</p>
          <p>
          Cookies are small text files that are stored on your device (computer or mobile device) when you visit a website. They are widely used to make websites work more efficiently and to provide information to website owners.
          </p>
        </div>

        <div className="how-we-use-section">
          <p className="title">How we use Cookies</p>
          <p>Hexagon uses Cookies for various reasons, including:</p>
          <ul>
            <li><span className="blue-highlight">Authentication:</span> We use cookies to authenticate users and ensure security while logged in. This includes storing user information such as usernames and access tokens securely.</li>
            <li><span className="blue-highlight">Session Management:</span> Cookies help us manage user sessions. When you log in to Hexagon, a session cookie is created to keep you logged in as you navigate through the website. This cookie is removed when you log out.</li>
            <li><span className="blue-highlight">Personalization:</span> We may use cookies to personalize your experience on our website, such as remembering your preferences or settings.</li>
            <li><span className="blue-highlight">Analytics:</span> We use cookies to gather anonymous analytics data to help us understand how users interact with our website and to improve our services.</li>
          </ul>
        </div>

        <div className="choices-section">
          <p className="title">Your Choices Regarding Cookies</p>
          <p>You can control and manage cookies in various ways. Most web browsers allow you to control cookies through their settings. You can also delete cookies stored on your device at any time.</p>
        </div>

        <div className="updates-section">
          <p className="title">Updates to this Policy</p>
          <p>We may update this Cookies Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically for any updates.</p>
        </div>
      </div>
    </>
  );
}

export default Cookies;