import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCrown } from "@fortawesome/free-solid-svg-icons";
import "../css/featured.css";

function FeaturedServers() {
  const [servers, setServers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const serversPerPage = 12;

  useEffect(() => {
    axios.get("https://www.hexagonbot.com/getServers")
      .then((res) => {
        const servers = res.data;
        setServers(servers);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        throw new Error("An error occurred while trying to get the servers");
      });
  }, []);

  const indexOfLastServer = currentPage * serversPerPage;
  const indexOfFirstServer = indexOfLastServer - serversPerPage;
  const displayedServers = servers.slice(indexOfFirstServer, indexOfLastServer);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(servers.length / serversPerPage);

  const renderPaginationButtons = () => {
    const maxButtonsToShow = 10;
    const startPage = Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxButtonsToShow - 1);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className={currentPage === i ? 'active' : ''}>
          <p onClick={() => paginate(i)}>{i}</p>
        </li>
      );
    }
    return buttons;
  };

  const sortedServers = [...displayedServers].sort((a, b) => {
    return b.isPremium - a.isPremium;
  });

  return (
    <>
    <div className="servers-container">
      {sortedServers.map((server) => (
        <div className="server" id={server.isPremium ? "server-premium" : "server"} key={server.GuildId}>
          <div className="server-icon">
            {server.guildIconUrl ? (
              <img src={server.guildIconUrl} alt="Icon" />
            ) : (
              <p>{server.name.substring(0, 2).toUpperCase()}</p>
            )}
          </div>
          <p className="server-name">
            {server.isPremium ? 
              (
              <>
                <FontAwesomeIcon icon={faCrown} className="premium-crown" />
                {server.name}
              </>
              ) : (
                <>
                  {server.name}
                </>
              )
            }
          </p>
          <p className="server-description">{server.description ? server.description : "A very interesting server!"}</p>
          <div className="server-buttons">
            <p className="member-count">{server.memberCount} Members</p>
            <a className="join" href={server.invite}>Join <FontAwesomeIcon icon={faExternalLinkAlt} className="icon" /></a>
          </div>
        </div>
      ))}
    </div>

    <ul className="pagination">
      {renderPaginationButtons()}
    </ul>
    </>
  );
}

export default FeaturedServers;