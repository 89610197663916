import { useEffect, useState } from "react";
import axios from "axios";
import "../css/staffComponent.css";

// Staff role Ids
const roleIds = {
  "Owner": "1109897498383896687",
  "Management": "1109897499231146075",
  "Developer": "1130257057031934012",
  "Administration": "1109897500174860339",
  "Moderation": "1109897500950806628",
  "Community Relations": "1197970096832925696",
  "Discord Moderation": "1218971106573287575",
  "Community Support": "1109897501886140446",
  "Staff": "1109899048787378206"
};

// Main react component function
function StaffComponent() {
  // Create the state
  const [staffMembers, setStaffMembers] = useState([]);

  useEffect(() => {
    // Function to get the staff members
    function getMembers() {
      axios.get(`https://www.hexagonbot.com/staffMembers`)
        .then(response => {
          const staffMembers = response.data
            .filter(member => member.roles.includes(roleIds["Staff"]))
            .map(member => {
              return {
                id: member.user.id,
                username: member.user.username,
                avatarURL: `https://cdn.discordapp.com/avatars/${member.user.id}/${member.user.avatar}.png`,
                roles: member.roles
              };
          });

          // Set the state
          setStaffMembers(staffMembers);
        })
        .catch(error => {
          throw new Error(`An error occurred while getting the staff members: ${error}`);
        });
      }
    getMembers();
  }, []);

  return (
    <div className="staff">
      <p className="title">Leadership <span className="blue-highlight">Team</span></p>
      <div className="leadership">
        {staffMembers
          .filter(member => member.roles.includes(roleIds["Owner"]) || member.roles.includes(roleIds["Management"]))
          .map(member => (
            <div key={member.id}>
              <img className="staff-img" src={member.avatarURL} alt={member.username} />
              <p>{member.username}</p>
              <p className="title-description">{member.roles.includes(roleIds["Owner"])? "Owner" : "Management"}</p>
            </div>
          ))}
      </div>

      <p className="title">Development <span className="blue-highlight">Team</span></p>
      <div className="development">
        {staffMembers
          .filter(member => member.roles.includes(roleIds["Developer"]))
          .map(member => (
            <div key={member.id}>
              <img className="staff-img" src={member.avatarURL} alt={member.username} />
              <p>{member.username}</p>
              <p className="title-description">Developer</p>
            </div>
          ))}
      </div>

      <p className="title">Community <span className="blue-highlight">Relations</span></p>
      <div className="community-relations">
        {staffMembers
          .filter(member => member.roles.includes(roleIds["Community Relations"]))
          .map(member => (
            <div key={member.id}>
              <img className="staff-img" src={member.avatarURL} alt={member.username} />
              <p>{member.username}</p>
              <p className="title-description">Moderation</p>
            </div>
          ))}
      </div>

      <p className="title">Moderation <span className="blue-highlight">Team</span></p>
      <div className="moderation-team">
        {staffMembers
          .filter(member => member.roles.includes(roleIds["Discord Moderation"]))
          .map(member => (
            <div key={member.id}>
              <img className="staff-img" src={member.avatarURL} alt={member.username} />
              <p>{member.username}</p>
              <p className="title-description">Moderation</p>
            </div>
          ))}
      </div>

      <p className="title">Community <span className="blue-highlight">Support</span></p>
      <div className="community-support">
        {staffMembers
          .filter(member => member.roles.includes(roleIds["Community Support"]))
          .map(member => (
            <div key={member.id}>
              <img className="staff-img" src={member.avatarURL} alt={member.username} />
              <p>{member.username}</p>
              <p className="title-description">Support Staff</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default StaffComponent;