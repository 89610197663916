import { useEffect, useState } from "react";
import axios from "axios";
import LineComponent from "../components/LineComponent.js";
import "../css/statusComponent.css";

// Main react component function
const StatusComponent = () => {
  // Create the states
  const [statusData, setStatusData] = useState({});
  const [activeMaintenances, setActiveMaintenances] = useState([]);
  const [activeMessages, setActiveMessages] = useState([
    // New 

    { title: "Increased Response Times", type: "Partial Service Disruption", updates: [
      { date: "26/05/24 20:24", prefix: "[Investigating]", text: "We are experiencing an unusually high amount of API requests." },
      { date: "30/05/24 12:52", prefix: "[Identified]", text: "We have identified the issue and are working to resolve it." },
      { date: "30/05/24 13:29", prefix: "[Monitoring]", text: "We have deployed a potential fix and are monitoring the results." },
      { date: "30/05/24 13:34", prefix: "[Resolved]", text: "Services have resumed." },
    ]},

    // Old

    { title: "API Unresponsive", type: "Service Disruption", updates: [
      { date: "04/05/24 00:55", prefix: "[Investigating]", text: "We are experiencing an issue with our server." },
      { date: "04/05/24 01:29", prefix: "[Identified]", text: "We have identified the issue and are working to resolve it." },
      { date: "07/05/24 11:39", prefix: "[Monitoring]", text: "We have implimented a potential fix and are monitoring the results." },
      { date: "07/05/24 01:29", prefix: "[Resolved]", text: "All services have resumed." }
    ]}
  ]);

  // Function to get the status color
  const getStatusColor = (opt) => {
    if (statusData.overallStatus === "Full System Outage") {
      return "#ae2727";
    }

    if (opt && opt === "website") {
      return statusData.websiteStatus === "Unresponsive" ? "#ae8827" : "#27ae60";
    } else if (opt && opt === "api") {
      return statusData.apiStatus === "Unresponsive" ? "#ae8827" : "#27ae60";
    } else if (opt && opt === "bot") {
      return statusData.botStatus === "Unresponsive" ? "#ae8827" : "#27ae60";
    }

    return statusData.overallStatus === "All Systems Operational" ? "#27ae60" : "#ae8827";
  };

  useEffect(() => {
    // Function to get the status
    const getStatus = () => {
      axios
        .get(`https://www.hexagonbot.com/getStatus`)
        .then((response) => {
          const data = response.data;

          let tempoaryStatusData = { activeIncidents: 0, daysSinceLast: data.daysSinceLast, botStatus: "Operational", overallStatus: "All Systems Operational", websiteStatus: "Operational", apiStatus: "Operational" };

          if (!data["api"] && !data["website"]) {
            tempoaryStatusData["overallStatus"] = "Full System Outage";
            tempoaryStatusData["activeIncidents"] = 2;
          } else if (!data["api"] || !data["website"]) {
            tempoaryStatusData["overallStatus"] = "Partial System Outage";
            tempoaryStatusData["activeIncidents"] = 1;
          }

          if (!data["website"]) {
            tempoaryStatusData["websiteStatus"] = "Unresponsive"
          }

          if (!data["api"]) {
            tempoaryStatusData["apiStatus"] = "Unresponsive"
          }

          // Set the state
          setStatusData(tempoaryStatusData);
        })
        .catch((error) => {
          throw new Error(`An error occured while getting the status: ${error}`);
        });
    };
    getStatus();
  }, []);

  return (
    <div className="service-status">
      <div className="status-header" style={{ backgroundColor: getStatusColor() }}>
        <p className="title">{statusData.overallStatus}</p>
        <p className="description">Updated a few seconds ago</p>
      </div>

      <div className="status-count">
        <div className="status-div">
          <p className="amount">{statusData.activeIncidents}</p>
          <p className="description">Active Incidents</p>
        </div>

        <div className="status-div">
          <p className="amount">{activeMaintenances.length}</p>
          <p className="description">Active Maintenances</p>
        </div>

        <div className="status-div">
          <p className="amount">{statusData.daysSinceLast}</p>
          <p className="description">Days Since Last Incident</p>
        </div>
      </div>

      <div className="services">
        <p className="section-title">Services</p>
        <div className="service-div">
          <p className="title">Website</p>
          <p className="description" style={{ color: getStatusColor("website") }}>{statusData.websiteStatus}</p>
        </div>

        <div className="service-div">
          <p className="title">Bot</p>
          <p className="description" style={{ color: getStatusColor("bot") }}>{statusData.botStatus}</p>
        </div>

        <div className="service-div">
          <p className="title">API</p>
          <p className="description" style={{ color: getStatusColor("api") }}>{statusData.apiStatus}</p>
        </div>
      </div>

      <div className="metrics">
        <p className="section-title">Metrics</p>
        <div className="metric-div">
          <div className="metric-info">
            <p className="metric-title">Response Time</p>
            <p className="metric-description">Website <span className="blue-highlight">Webhooks</span></p>
          </div>

          <LineComponent />
        </div>
      </div>

      <div className="messages">
        <p className="section-title">Messages</p>
        {activeMessages.map((message) => (
          <div className="message" key={message.id}>
            <div className="top-header">
              <p className="title">{message.title}</p>
              <p className="type">{message.type}</p>
            </div>
            <div className="updates">
              {message.updates.map((update) => (
                <div className="update">
                  <p className="date">{update.date}</p>
                  <p className="description"><span className="white-text-bold">{update.prefix}</span> {update.text}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusComponent;