import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import LZString from "lz-string";

// Main react component function
const DiscordLoginCallback = ({ setIsLoggedIn }) => {
  // Get the URL
  const location = useLocation();

  useEffect(() => {
    // Function to get the discord authentication token from the URL
    const getTokenFromURL = () => {
      const searchParams = new URLSearchParams(location.hash.substring(1));
      return searchParams.get("access_token");
    };

    // Function to check if the user is already logged in, if they are set the state
    const checkAccountTokenCookie = () => {
      const accessToken = Cookies.get("discordAccessToken");
      if (accessToken) {
        setIsLoggedIn(true);
      }
    };

    // Function to compress cookie data, reducing the size
    const compressData = (data) => {
      const json = JSON.stringify(data);
      const compressed = LZString.compressToBase64(json);
      return compressed;
    };

    // Function to fetch user data from the discord API
    const fetchUserData = async (accessToken) => {
      try {
        const userResponse = await fetch("https://discord.com/api/v9/users/@me", {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (userResponse.ok) {
          const userData = await userResponse.json();
          const userDataSchemaReq = await axios.get(`https://www.hexagonbot.com/userData?id=${userData.id}`);
          const userSchemaDataResponse = await userDataSchemaReq.data;

          const avatarUrl = userData.avatar ? `https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.png` : null;
          const userInfo = { username: userData.username, avatar: avatarUrl, displayName: userData.global_name, isPremium: userSchemaDataResponse.isPremium, userId: userData.id }

          Cookies.set("userInfo", JSON.stringify(userInfo), { secure: true });
        } else {
          throw new Error(`An error occurred while fetching the user data: ${userResponse.status}`);
        }
      } catch(error) {
        throw new Error(`An unexpected error occurred while fetching the user data: ${error}`);
      }
    };

    // Function to fetch user guilds from the discord API
    const fetchGuilds = async (accessToken) => {
      const response = await fetch("https://discord.com/api/v9/users/@me/guilds", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const responseGuilds = await response.json();

        const promises = responseGuilds.map(async guild => {
          if (guild.id === "972946898300305439") return; // This is the hexagon legacy server, very big and takes a long time to go through it
          // We need to stop big servers from returning anything. Or find a better way of getting their data

          if (guild.owner) {
            const guildResponse = await axios.get(`https://www.hexagonbot.com/guildData?guildId=${guild.id}`)
              .catch(() => {
                return null;
              });

            if (!guildResponse) {
              return null;
            }

            const guildData = guildResponse.data;
            const guildRoles = guildData.roles.map(role => {
              return { id: role.id, name: role.name };
            });

            return {
              id: guild.id,
              name: guild.name,
              guildIconUrl: guildData.guildIconUrl,
              memberCount: guildData.memberCount,
              textChannelsCount: 0,
              voiceChannelsCount: 0,
              categoryCount: 0,
              otherChannelsCount: 0,
              rolesCount: guildRoles.length,
              roles: guildRoles
            };
          } else {
            return null;
          }
        });

        const guildsWithRoles = await Promise.all(promises);

        const ownedGuilds = guildsWithRoles.filter(guild => guild)
          .map(guild => ({ name: guild.name, icon: guild.guildIconUrl, id: guild.id, memberCount: guild.memberCount, textChannelsCount: guild.textChannelsCount, voiceChannelsCount: guild.voiceChannelsCount, categoryCount: guild.categoryCount, otherChannelsCount: guild.otherChannelsCount, rolesCount: guild.rolesCount }));

        const ownedGuildsRoles = guildsWithRoles.filter(guild => guild)
          .map(guild => ({ id: guild.id, roles: guild.roles }));

        const compressedGuilds = compressData(ownedGuilds);
        const compressedGuildsRoles = compressData(ownedGuildsRoles);

        // Set the cookies
        Cookies.set("discordAccessToken", accessToken, { secure: true });
        Cookies.set("discordOwnedGuilds", compressedGuilds, { secure: true });
        Cookies.set("discordOwnedGuildsRoles", compressedGuildsRoles, { secure: true });

        // Set the state and redirect to the dashboard
        setIsLoggedIn(true);
        window.location.replace("/dashboard");
      } else {
        throw new Error("Unable to fetch user guilds");
      }
    };

    // If the token doesn't exist, check if the user is already logged in
    // If it does exist call fetchUserData and fetchGuilds
    const accessToken = getTokenFromURL();
    if (!accessToken) {
      checkAccountTokenCookie();
    } else {
      fetchUserData(accessToken);
      fetchGuilds(accessToken);
    }
  }, [location, setIsLoggedIn]);

  return null;
};

export default DiscordLoginCallback;