import { Helmet } from "react-helmet";
import "../css/privacy.css";

// Main react page function
function Privacy() {
  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Privacy Policy - Hexagon</title>
      </Helmet>

      <div className="container-privacy">
        <p className="title">Privacy Policy</p>
        <p className="description">Last Updated: March 17th, 2024</p>
        <div className="welcome-section">
          <p className="title">Welcome!</p>
          <p>
            Thank you for using Hexagon! This Privacy Policy outlines how Hexagon collects, uses, maintains, and discloses information collected from users (hereinafter referred to as "Users") of the Hexagon Discord bot (hereinafter referred to as "Hexagon").<br/><br/>
            Hexagon is committed to protecting the privacy of its Users and ensuring the security of personal information provided to us. This Privacy Policy applies to the Hexagon Discord bot and all related services offered by Hexagon.
          </p>
        </div>
        <div className="information-section">
          <p className="title">Information we collect automatically</p>
          <p>
            We collect certain information when you use our services. This includes information we recieve automatically, outlined below.
          </p>
          <ul>
            <li><span className="blue-highlight">Account Information:</span> When you sign in to one of our services, we collect certain account information from Discord. This includes the guilds you are in, your user ID, and any relevant information from guilds you own.</li>
          </ul>
        </div>
        <div className="usage-section">
          <p className="title">How we use your information</p>
          <p>
            The information we collect is used for the following reasons:
          </p>
          <ul>
            <li><span className="blue-highlight">Services:</span> We use your information to provide your with the Hexagon services. For example, when you sign-in to the Bot's Web Dashboard to make changes, we use your guild information to make that work.</li>
            <li><span className="blue-highlight">Advertisers:</span> Your information may be shared with advertisers to serve you with relevant ads.</li>
            <li><span className="blue-highlight">Customer Service:</span> We use your information to respond to your questions about our products and services, and to investigate bugs or other issues.</li>
          </ul>
        </div>
        <div className="disclosure-section">
          <p className="title">How we disclose your information</p>
          <p>
            The information we collect is disclosed for the following reasons:
          </p>
          <ul>
            <li><span className="blue-highlight">When you tell us to:</span> When you use our services, you voluntarily share your data with us. If you request to access this data at any time, we will provide it to you.</li>
            <li><span className="blue-highlight">To comply with the law:</span> We may disclose information in response to a request for information if we believe disclosure is required by law, including meeting national security or law enforcement requirements. When allowed we will attempt to provide you with notice of this request.</li>
            <li><span className="blue-highlight">Advertisers:</span> Relevant Cookies may be shared with advertisers to serve you with more relevant ads.</li>
            <li><span className="blue-highlight">Sale, Acquisition, or Transfer of Assets:</span> We may disclose information if Hexagon is engaging in a merger, acquisition, reorganization, backruptcy or sale, transfer, or change in ownership of Hexagon and any of its assets.</li>
          </ul>
        </div>
        <div className="protection-section">
          <p className="title">How we protect your information</p>
          <p>
            We take a number of steps to help protect your information. All information sent within our services is encryped. We also enforce technical access controls to limit which of our employees can access nonpublic information.
          </p>
        </div>
        <div className="control-section">
          <p className="title">How to control your privacy</p>
          <p>
            We believe that users should be able to tailor their experience to their preferences, including privacy. We're required by Discord to delete all nonpublic data upon a users request. You can make this request by contacting our support team.
          </p>
        </div>
        <div className="changes-section">
          <p className="title">Changes to this Privacy Policy</p>
          <p>
            We will update this Privacy Policy from time to time. We will always indicate the date the last changes were published, and if the changes are significant we will provide you with a more prominent notice as required by law, such as emailing you or highlighting the changes within the services.
          </p>
        </div>
      </div>
    </>
  );
}

export default Privacy;