import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import FeaturedServers from "../components/FeaturedServers.js";
import '../css/servers.css';

// Main react page function
function Servers() {
  // Create the states
  const [servers, setServers] = useState("0");

  useEffect(() => {
    async function updateServers() {
      await axios.get("https://www.hexagonbot.com/botStats")
      .then(response => {
        const data = response.data;

        // Set the state
        setServers(data.totalGuilds);
      })
      .catch(error => {
        throw new Error(`An error occured while fetching the bot data: ${error}`);
      });
    };
    updateServers();
  }, []);

  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Servers - Hexagon</title>
      </Helmet>

      <div className="landing-section-servers">
        <p className="title">Public Servers</p>
        <p className="description">A quality, well-made listing service that offers users a completely fair and unbiased list of servers for you to explore and join.</p>
        <p className="description">Hexagon is currently in <span className="blue-highlight">{servers}</span> servers and we are growing daily!</p>
      </div>

      <div className="servers-section">
        <p className="title">All Listed Servers</p>
        <FeaturedServers />
      </div>
    </>
  );
}

export default Servers;