import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import logo from "../images/logo.png";
import "../css/navigation.css";

const NavigationBar = ({ isLoggedIn, setIsLoggedIn }) => {
  // Create the states
  const [username, setUsername] = useState(""); 
  const [hamburgerMenuVisible, setHamburgerMenuVisible] = useState(false);

  // Function to logout the user
  const logoutUser = () => {
    try {
      Cookies.remove("discordAccessToken");
      setIsLoggedIn(false);
    } catch(error) {
      throw new Error("An unexpected error occured while trying to logout the user")
    }
  };

  // Function to show the hamburger menu
  const showHamburgerMenu = () => {
    try {
      const element = document.querySelector(".hamburger-dropdown");
      if (!element) throw new Error("Unable to locate dropdown element");
      if (hamburgerMenuVisible) {
        element.style.display = "none";
        setHamburgerMenuVisible(false);
      } else {
        element.style.display = "flex";
        setHamburgerMenuVisible(true);
      }
    } catch(error) {
      throw new Error("An unexpected error occured while trying to show the hamburger menu")
    }
  };

  useEffect(() => {
    // Get the user info cookie
    const userInfoCookie = Cookies.get("userInfo");

    // Set the front-end user data, or set a placeholder
    if (userInfoCookie) {
      const userInfo = JSON.parse(userInfoCookie);
      setUsername(userInfo.username);
    } else {
      setUsername("User");
    }
  }, []);

  return (
    <>
      <div className="nav-bar">
        <img src={logo} alt="logo" width="50px" height="50px"></img>
        <a href="https://www.hexagonbot.com/" className="title">HEXAGON</a>

        <label htmlFor="check" className="hamburger-menu">
          <input type="checkbox" id="check" onClick={() => { showHamburgerMenu() }}/>
          <p></p>
          <p></p>
          <p></p>
        </label>
        
        <div className="buttons">
          <a href="https://www.hexagonbot.com/">Home</a>
          <a href="https://discord.gg/UWA8ujD7Th">Join our Discord</a>
          <a href="https://www.hexagonbot.com/staff">Staff</a>
          <a href="https://www.hexagonbot.com/servers">Public Servers</a>
          <a href="https://www.hexagonbot.com/status">Status</a>
          <a href="https://www.hexagonbot.com/premium" className="premium-button">Premium</a>
        </div>

        <div className="buttons-right"> 
          <a href="https://discord.com/api/oauth2/authorize?client_id=1078264877744930827&permissions=8&scope=bot" className="add-to-server-button">Add to Server</a>
          
          {isLoggedIn === false ? (
              <>
                <a href="https://discord.com/oauth2/authorize?client_id=1078264877744930827&response_type=token&redirect_uri=https%3A%2F%2Fwww.hexagonbot.com%2F&scope=identify+guilds+guilds.members.read" className="login-button">Login</a>
                <a href="https://discord.com/oauth2/authorize?client_id=1078264877744930827&response_type=token&redirect_uri=https%3A%2F%2Fwww.hexagonbot.com%2F&scope=identify+guilds+guilds.members.read" className="login-button-mobile"><FontAwesomeIcon icon={faUser} /></a>
              </>
          ) : (
              <>
                <a href="/dashboard" className="dashboard-button">Dashboard</a>
              </>
          )}
        </div>

        <div className="account-buttons">
          {isLoggedIn && (
              <>
                <p className="account-username" id="account-username">@{username}</p>
                <p className="logout-button" onClick={logoutUser}><FontAwesomeIcon icon={faSignOutAlt} /></p>
              </>
          )}
        </div>
      </div>

      <div className="hamburger-dropdown">
        <a href="https://www.hexagonbot.com/">Home</a>
        <a href="https://discord.gg/UWA8ujD7Th">Join our Discord</a>
        <a href="https://www.hexagonbot.com/dashboard">Dashboard</a>
        <a href="https://www.hexagonbot.com/staff">Staff</a>
        <a href="https://www.hexagonbot.com/servers">Public Servers</a>
        <a href="https://www.hexagonbot.com/status">Status</a>
        <a href="https://www.hexagonbot.com/premium">Premium</a>
      </div>
    </>
  );
}

export default NavigationBar;