import { Helmet } from 'react-helmet';
import StaffComponent from '../components/StaffComponent.js';
import '../css/staff.css';
import logo from '../images/logo.png';

// Main react page function
function Staff() {
  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Staff</title>
      </Helmet>

      <div className="landing-section-staff">
        <img src={logo} alt="logo" width="100px" height="100px"></img>
        <p className="title">Hexagon Staff</p>
        <p className="description">Introducing our exceptional team, comprised of talented individuals who are the driving force behind Hexagon's success.</p>
        <p className="description">With their diverse skill sets and unwavering dedication, they ensure that Hexagon not only stays in motion but also excels in the face of any challenge.</p>

        <div className="staff">
          <StaffComponent />
        </div>
      </div>
    </>
  );
}

export default Staff;