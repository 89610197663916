import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faContactBook, faCookie, faInbox, faLock, faPerson, faServer } from "@fortawesome/free-solid-svg-icons";
import "../css/footer.css";

// Main react component function
function Footer() {
  return (
    <>
    <div className="footer">
        <div className="container">
        <div>
            <h4>Hexagon Bot</h4>
            <p>© Hexagon Interactive</p>
        </div>
        <div>
            <h4>Links</h4>
            <div className="list">
                <a href="https://www.hexagonbot.com/status"><FontAwesomeIcon className="fa-icon" icon={faServer} width="12.5px" /> Status</a>
                <a href="https://www.hexagonbot.com/contact"><FontAwesomeIcon className="fa-icon" icon={faInbox} width="12.5px" /> Contact</a>
            </div>
        </div>
        <div>
            <h4>Legal</h4>
            <div className="list">
                <a href="https://www.hexagonbot.com/privacy"><FontAwesomeIcon className="fa-icon" icon={faLock} width="12.5px" /> Privacy</a>
                <a href="https://www.hexagonbot.com/cookies"><FontAwesomeIcon className="fa-icon" icon={faCookie} width="12.5px" /> Cookies</a>
                <a href="https://www.hexagonbot.com/terms"><FontAwesomeIcon className="fa-icon" icon={faContactBook} width="12.5px" /> Terms</a>
            </div>
        </div>
        <div>
            <h4>Careers</h4>
            <div className="list">
                <a href="https://www.hexagonbot.com/careers"><FontAwesomeIcon className="fa-icon" icon={faPerson} width="12.5px" /> Positions</a>
            </div>
        </div>
        </div>
    </div>
    </>
  );
}

export default Footer;