import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCog, faSave, faTachometerAlt, faLayerGroup, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import LZString from "lz-string";
import axios from "axios";
import CommandsComponent from "../components/CommandsComponent";
import LogsComponent from "../components/LogsComponent";
import { Helmet } from "react-helmet";
import logo from "../images/logo.png";
import "../css/manage.css";

// Decompress the data from base64, cookies are stored as base64 to reduce size
const decompressData = (compressedString) => {
  let returning;
  try {
    const decompressed = LZString.decompressFromBase64(compressedString);
    returning = JSON.parse(decompressed);
  } catch(error) {
    return;
  }
  return returning;
};

// Main react page function
function GuildManage() {
  // Get the guild id from the url parameters
  const { guildId } = useParams();

  // Store the guild data as a state, and the loading bool as a state
  const [guildData, setGuildData] = useState({});
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("Dashboard");
  const [nickname, setNickname] = useState("Hexagon");
  const [openResponse, setOpenResponse] = useState("Thank you for opening a ticket. We're here to help. We will do our best to respond to you within 24hrs.");
  const [closeResponse, setCloseResponse] = useState("We hope you had a good experience.");

  useEffect(() => {
    // Get the cookie
    const userGuildsCookie = Cookies.get("discordOwnedGuilds");

    if (userGuildsCookie) {
      // Decompress the cookie
      const userGuilds = decompressData(userGuildsCookie);

      const guild = userGuilds.find(guild => guild.id === guildId);
      
      // Ensure the data exists then set it using the state, or set placeholders
      if (guild) {
        setGuildData(guild);
      } else {
        setGuildData({ name: "Placeholder", memberCount: 0 });
      }
    } else {
      setGuildData({ name: "Placeholder", memberCount: 0 });
    }

    // Change the loading state
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [guildId]);

  function handleClick(value) {
    setTab(value);
  };

  async function saveChanges() {
    await axios.post(`https://www.hexagonbot.com/websocket`, {
      nickname: nickname,
      guildId: guildId,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  async function saveTicketChanges() {
    await axios.post('https://www.hexagonbot.com/websocket', {
      openResponse: openResponse,
      closeResponse: closeResponse,
      guildId: guildId,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
  }

  const handleCloseChange = (event) => {
    setCloseResponse(event.target.value);
  }

  const handleOpenChange = (event) => {
    setOpenResponse(event.target.value);
  }

  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Manage</title>
      </Helmet>

      {/* If the loading state is true then show the loading page */}
      {loading ? (
        <div className="loading-container">
          <div className="loading-circle"></div>
        </div>
      ) : (
        <>
          <div className="side-nav-bar">
            <div className="info">
              <img alt="icon" src={logo}></img>
              <a href="https://www.hexagonbot.com/">Hexagon</a>
            </div>
            <div className="buttons">
              <p onClick={() => handleClick("Dashboard")}><span className="transparent-highlight"><FontAwesomeIcon icon={faTachometerAlt} /></span> Dashboard</p>
              <p onClick={() => handleClick("Commands")}><span className="transparent-highlight"><FontAwesomeIcon icon={faLayerGroup} /></span> Commands</p>
              <p onClick={() => handleClick("Logs")}><span className="transparent-highlight"><FontAwesomeIcon icon={faFloppyDisk} /></span> Logs</p>
            </div>
          </div>

          {tab === "Dashboard" && (
            <>
              <div className="landing-section">
                <div className="guild-info">
                  <div className="icon">
                    {guildData.icon ? (
                      <img src={guildData.icon} alt="Icon" />
                    ) : (
                      <p>{guildData.name.substring(0, 2).toUpperCase()}</p>
                    )}
                  </div>
                  <div className="container">
                    <p className="title">{guildData.name}</p>
                    <p className="description">{guildData.memberCount} Members</p>
                  </div>
                </div>
              </div>

              <div className="guild-info-section">
                <p className="title">Server Information</p>
                <div className="container">
                  <p className="categories">Categories: <span className="white-highlight">{guildData.categoryCount}</span></p>
                  <p className="text-channels">Text Channels: <span className="white-highlight">{guildData.textChannelsCount}</span></p>
                  <p className="voice-channels">Voice Channels: <span className="white-highlight">{guildData.voiceChannelsCount}</span></p>
                  <p className="text-channels">Other Channels: <span className="white-highlight">{guildData.otherChannelsCount}</span></p>
                  <p className="roles">Roles: <span className="white-highlight">{guildData.rolesCount}</span></p>
                </div>
                <div className="other-options">
                  <p className="copy-id"><span className="blue-highlight"><FontAwesomeIcon icon={faCopy} /> Copy Id</span></p>
                  <p className="report-issue"><span className="blue-highlight"><FontAwesomeIcon icon={faCog} /> Report Issue</span></p>
                </div>
              </div>

              <div className="bot-settings-section">
                <p className="title">Bot Settings</p>
                <div className="container">
                  <div className="option">
                    <p>Nickname:</p>
                    <input value={nickname} maxLength={32} onChange={handleNicknameChange}/>
                  </div>
                </div>
                <div className="other-options">
                  <p className="save-changes" onClick={() => {saveChanges()}}><span className="blue-highlight"><FontAwesomeIcon icon={faSave} /> Save Changes</span></p>
                </div>
              </div>

              <div className="ticket-settings-section">
                <p className="title">Ticket Settings</p>
                <div className="container">
                  <div className="option">
                    <p>Open Response:</p>
                    <input value={openResponse} maxLength={128} onChange={handleOpenChange}/>
                  </div>
                  <div className="option">
                    <p>Close Response:</p>
                    <input value={closeResponse} maxLength={128} onChange={handleCloseChange}/>
                  </div>
                </div>
                <div className="other-options">
                  <p className="save-changes" onClick={() => {saveTicketChanges()}}><span className="blue-highlight"><FontAwesomeIcon icon={faSave} /> Save Changes</span></p>
                </div>
              </div>
            </>
          )}
          {tab === "Commands" && (
            <>
              <CommandsComponent />
            </>
          )}
          {tab === "Logs" && (
            <>
              <LogsComponent />
            </>
          )}
        </>
      )}
    </>
  );
}

export default GuildManage;