import React, { useState, useEffect } from "react";
import logo512 from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faDesktop, faServer } from "@fortawesome/free-solid-svg-icons";
import "../css/gdpr.css";

function GdprMessage() {
    const [IsVisible, SetIsVisible] = useState(false);

    useEffect(() => {
        const Consent = localStorage.getItem("gdprConsent");
        if (!Consent) {
            SetIsVisible(true);
        }
    }, []);

    const HandleConsent = () => {
        localStorage.setItem("gdprConsent", "accepted");
        SetIsVisible(false);
        // Additional logic for accepting cookies
    };

    const HandleOptions = () => {
        // Additional logic for showing options
    };

    return (
        <div>
            {IsVisible && (
                <div className="gdpr-modal">
                    <div className="gdpr-modal-content">
                        <img src={logo512} alt="Logo" />
                        <h1>Hexagon asks for your consent to use your personal data to:</h1>
                        <ul>
                            <li><span className="span-gdpr-icon"><FontAwesomeIcon className="gdpr-icon" icon={faDesktop} /></span>Store and/or access information about you</li>
                            <li><span className="span-gdpr-icon"><FontAwesomeIcon className="gdpr-icon" icon={faServer} /></span>Maintain, manage and update your data</li>
                            <li><span className="span-gdpr-icon"><FontAwesomeIcon className="gdpr-icon" icon={faClipboard} /></span>Personalized ads, content measurement, and audience insights</li>
                        </ul>
                        <p>We use cookies to ensure that we give you the best experience on our website. If you continue, we'll assume that you are happy to receive all cookies on this website.</p>
                        
                        <div className="gdpr-modal-buttons">
                            <button onClick={HandleOptions} className="options-btn">Manage Options</button>
                            <button onClick={HandleConsent} className="accept-btn">Consent</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GdprMessage;