import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";

import NavigationBar from "./components/NavigationBar.js";
import Home from "./pages/Home.js";
import Staff from "./pages/Staff.js";
import Status from "./pages/Status.js";
import Premium from "./pages/Premium.js";
import GuildManage from "./pages/GuildManage.js";
import Dashboard from "./pages/Dashboard.js";
import Webhooks from "./pages/Webhooks.js";
import Privacy from "./pages/Privacy.js";
import Terms from "./pages/Terms.js";
import Cookies from "./pages/Cookies.js";
import Servers from "./pages/Servers.js";
import Careers from "./pages/Careers.js";
import Contact from "./pages/Contact.js";
import DiscordLoginCallback from "./components/DiscordLoginCallback.js";
import Footer from "./components/Footer.js";
import GdprMessage from "./components/GdprMessage";

import "./css/app.css";

const subdomain = window.location.hostname.split(".")[0];

// Main react app function
function App() {
  // Create the states
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNavigationBarVisible, setIsNavigationBarVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const showNavigationBar = !location.pathname.startsWith("/dashboard/");
    
    setIsNavigationBarVisible(showNavigationBar);
  }, [location.pathname]);

  return (
    <div className="app-container">
      {isNavigationBarVisible && (
        <NavigationBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      )}
      <DiscordLoginCallback setIsLoggedIn={setIsLoggedIn} />
      
      <Routes>
          <>
            <Route path="/" element={<Home />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/status" element={<Status />} />
            <Route path="/premium" element={<Premium />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/webhooks" element={<Webhooks />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/servers" element={<Servers />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dashboard/:guildId" element={<GuildManage />} />
          </>

        {subdomain === "webhooks" && (
          <>
            <Route path="/" element={<Navigate to="https://www.hexagonbot.com/webhooks" />} />
          </>
        )}
      </Routes>

      {/* <GdprMessage /> */}

      {isNavigationBarVisible && (
        <Footer />
      )}
    </div>
  );
}

function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default MainApp;