import { useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import "../css/contact.css";

// Main react page function
function Contact() {
  // Create the states
  const [emailValue, setEmailValue] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [contentValue, setContentValue] = useState("");
  
  // Function to handle input
  const handleInputChangeEmail = (event) => {
    setEmailValue(event.target.value);
  };

  const handleInputChangeSubject = (event) => {
    setSubjectValue(event.target.value);
  };

  const handleInputChangeContent = (event) => {
    setContentValue(event.target.value);
  };

  return (
    <>
      {/* Page header */}
      <Helmet>
        <title>Contact Us - Hexagon</title>
      </Helmet>
      <div className="contact-container">
        <div className="contact-box">
          <p className="title">Contact Us</p>
          <p className="description">Get in touch with one of our team members via email or discord ticket.</p>
          <input
            type="text"
            className="input-box"
            spellCheck="false"
            placeholder="Email"
            value={emailValue}
            onChange={handleInputChangeEmail}
          />
          <input
            type="text"
            className="input-box"
            spellCheck="false"
            placeholder="Subject"
            value={subjectValue}
            onChange={handleInputChangeSubject}
          />
          <textarea
            type="text"
            className="input-box"
            spellCheck="false"
            placeholder="Content"
            value={contentValue}
            onChange={handleInputChangeContent}
          />
          <div className="buttons">
            <a href="https://discord.gg/UWA8ujD7Th" className="support-invite"><FontAwesomeIcon icon={faExternalLinkAlt} className="icon" /> Discord Support</a>
            <a className="send-email">Send Email</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;